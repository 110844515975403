import{formatFixed}from"@ethersproject/bignumber";import{parseFixed}from"@ethersproject/bignumber";export var isNullOrEmpty=function isNullOrEmpty(tStr){if(!tStr||tStr==""||tStr==undefined){return true;}return false;};/**
 * 位数补全 不够的位数用0替代
 * @param tNum   源数值
 * @param tCount 目标位数
 * @returns 
 * 
 * 示例： fillDigit(13, 4) 返回 '0013'
 */export var fillDigit=function fillDigit(tNum,tCount){var numStr=String(tNum);var curNumLen=numStr.length;if(curNumLen>=tCount){return numStr;}var diValue=tCount-curNumLen;while(diValue--){numStr='0'+numStr;}return numStr;};/**
* 把字符串 首字母大写
* @param tStr 
* @returns 
*/export var titleUpperCase=function titleUpperCase(tStr){if(!tStr)return tStr;return tStr.toLowerCase().replace(/( |^)[a-z]/g,function(tL){return tL.toUpperCase();});};/**
 * 省略名称显示
 * @param tStr      源字符串
 * @param tPreShow  前面显示的数量
 * @param tLastShow 后面显示的数量
 * @param tChar     省略的部分代替字符
 * @returns 
 * 
 * 示例：
 * omitStr('1234567489',3,2,'...') 输出123...89
 *  
 */export var omitStr=function omitStr(tStr,tPreShow,tLastShow,tChar){if(!tStr)return tStr;var len=tStr.length;if(len<=tPreShow+tLastShow){return tStr;}return"".concat(tStr.slice(0,tPreShow)).concat(tChar).concat(tStr.slice(len-tLastShow));};export var omitName=function omitName(tStr){if(!tStr)return tStr;return omitStr(tStr,5,4,'...');};/**
 * 移除后面无意义的小数 
 * 0.111100000  =》  0.1111
 * 2.000000000  =》  2
 * @param tStr 
 * @returns 
 */export var removeUnUseDecimalPoint=function removeUnUseDecimalPoint(tStr){if(!tStr)return tStr;if(!/^\d*\.\d*$/.test(tStr))return tStr;var newLen=tStr.length;while(newLen){if(tStr[newLen-1]=='0'){newLen=newLen-1;}else if(tStr[newLen-1]=='.'){newLen=newLen-1;break;}else{break;}}// return tStr.replace(/\.\d*/, '');
return tStr.slice(0,newLen);};export var removeAllDecimalPoint=function removeAllDecimalPoint(tStr){if(!tStr)return tStr;if(!/^\d*\.\d*$/.test(tStr))return tStr;// return tStr.replace(/\.\d*/, '');
return tStr.slice(0,tStr.indexOf("."));};export var fixedDecimalPoint=function fixedDecimalPoint(tStr){var num=arguments.length>1&&arguments[1]!==undefined?arguments[1]:2;if(!tStr)return tStr;if(!/^\d*\.\d*$/.test(tStr))return tStr;// return tStr.replace(/\.\d*/, '');
var pointIndex=tStr.indexOf(".");var main=removeAllDecimalPoint(tStr);var decimals=[];for(var i=1;i<=num;i++){decimals.push(tStr[pointIndex+i]||'0');}return"".concat(main,".").concat(decimals.join(''));};export var formatWei2Price=function formatWei2Price(tPrice){var decimals=arguments.length>1&&arguments[1]!==undefined?arguments[1]:18;if(tPrice===undefined||tPrice===null||tPrice==''){return tPrice;}var targetPrice=tPrice;if(tPrice.indexOf('.')!=-1){targetPrice=removeAllDecimalPoint(tPrice);console.warn('formatWei2Price param warn:'+tPrice);}var format=formatFixed(targetPrice,decimals);return removeUnUseDecimalPoint(format);};export var formatPrice2Wei=function formatPrice2Wei(tPrice){var decimals=arguments.length>1&&arguments[1]!==undefined?arguments[1]:18;if(tPrice===undefined||tPrice===null||tPrice==''){return tPrice;}var splits=tPrice.split('.');var targetPrice=tPrice;var mainStr=splits[0];var dotStr=splits[1];if(dotStr&&dotStr.length>decimals){targetPrice=mainStr+'.'+dotStr.slice(0,decimals);console.warn('formatPrice2Wei param warn:'+tPrice);}var format=parseFixed(targetPrice,decimals);return format.toString();};export var removeSpace=function removeSpace(tValue){return tValue.replace(/ */g,'');};export var toLocaleString=function toLocaleString(tValue){if(tValue===null||tValue===undefined)return'';var value=null;if(typeof tValue=='string'){if(tValue=='')return'';value=parseFloat(tValue);}else{value=tValue;}return value.toLocaleString();};export var firstUpperCase=function firstUpperCase(str){return str.replace(/\b(\w)(\w*)/g,function($0,$1,$2){return $1.toUpperCase()+$2.toLowerCase();});};